
import './App.css';
import {useState} from "react"
import Axios from "axios"

function App() {
const [empid,setEmpid]=useState(0);
const [name,setName]=useState("");
const [desig,setDesig]=useState("");
const [bsal,setBsal]=useState(0);
const [EmployeeList,setEmployeeList]=useState([]);

const AddInfo=()=>{
Axios.post('http://mytestapi.epmdtu.lk/create',
{
  empid:empid,
  name:name,
  desig:desig,
  bsal:bsal
}).then(()=>{
  console.log("Front Sucess");

});

};

const GetInfo=()=>{
  Axios.get('http://mytestapi.epmdtu.lk/employee').then((response)=>{
    setEmployeeList(response.data)
  
  });
  
  };

  const DelInfo=(eid)=>{
    Axios.post('http://mytestapi.epmdtu.lk/delete',{empid:eid}).then((response)=>{
      setEmployeeList(response.data)
    //console.log(response)
    });
    
    };


  return (
    <div className="App">
    <div className="information">
     <label>Employee Number</label>
     <input type="number" onChange={event=>{setEmpid(event.target.value)}}/>
     <label>Name</label>
     <input type="text" onChange={event=>{setName(event.target.value)}}/>
     <label>Designation</label>
     <input type="text" onChange={event=>{setDesig(event.target.value)}}/>
     <label>Basic Salary</label>
     <input type="number" onChange={event=>{setBsal(event.target.value)}}/>
     <button onClick={AddInfo}>Add Employee</button>
     <button onClick={GetInfo}>Get Employee List</button>
     <div>
     <table border='1'>
    {EmployeeList.map((emp,key)=>{
      return (<tr key={key}><td>{emp.empno}</td><td>{emp.ename}</td> <td>{emp.desig}</td><td>{emp.bsal}</td><td><button onClick={()=>{DelInfo(emp.empno)}}>Delete</button></td></tr>)
    })}
    </table>
     </div>
     </div>
    </div>
  );
}

export default App;
